import { Checkbox, Col, Input, Row, Select, Space, Typography } from 'antd';
import { egeniePcTheme } from 'egenie-common';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { ScanWrapper } from '../../utils';
import styles from './index.less';
import { Store } from './store';

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      uniqueCode,
      uniqueCodeRef,
      handleUniqueCode,
      handleUniqueCodePressEnter,
      waveList,
      waveValue,
      handleWaveChange,
      printUniqueCode,
      handlePrintUniqueCode,
      detailItem,
    } = this.props.store;

    return (
      <>
        <h2 style={{ paddingBottom: egeniePcTheme.spacing['egenie-spacing-sm'] }}>
          请选择波次号
        </h2>
        <Select
          allowClear
          filterOption={(inputValue: string, path) => {
            return [
              _.toString(path?.cartNo)
                .toLowerCase(),
              _.toString(path?.serialNo)
                .toLowerCase(),
            ].some((item) => item.includes(inputValue.toLowerCase()));
          }}
          onChange={handleWaveChange}

          // @ts-ignore
          options={waveList}
          placeholder="请选择波次号"
          showSearch
          size="large"
          style={{ width: '100%' }}
          value={waveValue}
        />
        <h2 style={{ padding: `${egeniePcTheme.spacing['egenie-spacing-sm']} 0` }}>
          请扫描唯一玛
        </h2>
        <Input
          allowClear
          autoComplete="off"
          className={styles.input}
          onChange={handleUniqueCode}
          onPressEnter={(event: any) => handleUniqueCodePressEnter(event.target.value)}
          placeholder="请扫描唯一玛"
          ref={uniqueCodeRef}
          size="large"
          value={uniqueCode}
        />
        <Space style={{ paddingTop: egeniePcTheme.spacing['egenie-spacing-sm'] }}>
          <Checkbox
            checked={printUniqueCode}
            onChange={handlePrintUniqueCode}
          >
            打印唯一码
          </Checkbox>
        </Space>
        <div className={styles.voiceContainer}>
          {detailItem.voice}
        </div>
      </>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const { detailItem } = this.props.store;
    return (
      <div className={styles.detail}>
        <section>
          <Row gutter={[
            8,
            8,
          ]}
          >
            <Col span={6}>
              <Typography.Title
                ellipsis
                level={3}
                title={detailItem.productName}
              >
                商品名称:&nbsp;&nbsp;
                {detailItem.productName}
              </Typography.Title>
            </Col>
            <Col span={6}>
              <Typography.Title
                ellipsis
                level={3}
                title={detailItem.skuNo}
              >
                SKU编码:&nbsp;&nbsp;
                {detailItem.skuNo}
              </Typography.Title>
            </Col>
            <Col span={6}>
              <Typography.Title
                ellipsis
                level={3}
                title={detailItem.color}
              >
                颜色:&nbsp;&nbsp;
                {detailItem.color}
              </Typography.Title>
            </Col>
            <Col span={6}>
              <Typography.Title
                ellipsis
                level={3}
                title={detailItem.size}
              >
                尺寸:&nbsp;&nbsp;
                {detailItem.size}
              </Typography.Title>
            </Col>
          </Row>
        </section>
        <div className={styles.imgContainer}>
          <Row
            gutter={[
              8,
              8,
            ]}
          >
            <Col span={8}>
              <div className={styles.detailItemContainer}>
                <Typography.Title
                  ellipsis
                  level={1}
                  title={detailItem.brandName}
                >
                  品牌:
                  {detailItem.brandName}
                </Typography.Title>
                <div
                  className={`${styles.img} egenie-common-img-fixed-shape`}
                  style={{ backgroundImage: detailItem.logoUrl ? `url(${detailItem.logoUrl})` : undefined }}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.detailItemContainer}>
                <Typography.Title
                  ellipsis
                  level={1}
                  title={detailItem.collarLabelUrl}
                >
                  领标
                  {detailItem.collarLabelUrl}
                </Typography.Title>
                <div
                  className={`${styles.img} egenie-common-img-fixed-shape`}
                  style={{ backgroundImage: detailItem.collarLabelUrl ? `url(${detailItem.collarLabelUrl})` : undefined }}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.detailItemContainer}>
                <Typography.Title
                  ellipsis
                  level={1}
                  title={detailItem.skuNo}
                >
                  SKU编码:
                  {detailItem.skuNo}
                </Typography.Title>
                <div
                  className={`${styles.img} egenie-common-img-fixed-shape`}
                  style={{ backgroundImage: detailItem.pic ? `url(${detailItem.pic})` : undefined }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <ScanWrapper
      leftChildren={<Left store={store}/>}
      leftTitle="换标"
      leftWidth={400}
      rightChildren={<Right store={store}/>}
      rightTitle="扫描结果"
    />
  );
}

