import type { InputRef } from 'antd';
import { printHelper } from 'egenie-common';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { ENUM_PLUS_SERVICE_TYPE, getAndPlayVoice, playVoice, voiceData } from '../../utils';
import type { Wave } from '../wavePickSorting/interface';
import type { DetailItem } from './types';

export class Store {
  constructor() {
    this.getWaveList();
  }

  @action public getWaveList = () => {
    request<BaseData<Wave>>({ url: '/api/cloud/wms/rest/sort/query/cond' })
      .then((info) => {
        this.waveList = (info.data?.orderList || []).map((item) => ({
          ...item,
          value: `${item.wmsPickingOrderId}`,
          label: `${item.serialNo}${item.waveName}`,
        }));
      });
  };

  @observable public waveValue: string | undefined = undefined;

  @action public handleWaveChange = (waveValue: string | undefined) => {
    this.waveValue = waveValue;
    this.uniqueCodeRef.current.focus();
  };

  @observable public waveList: Wave['orderList'] = [];

  @observable public printUniqueCode = false;

  @action public handlePrintUniqueCode = (event): void => {
    this.printUniqueCode = event.target.checked;
  };

  @observable public detailItem: DetailItem = {};

  @observable public uniqueCode = '';

  public uniqueCodeRef: React.RefObject<InputRef> = React.createRef();

  @action public handleUniqueCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.uniqueCode = event.target.value;
  };

  private printCertificateOrWaterWashingLabel = async(detailItem: DetailItem, tempId: string | number, tempType: string) => {
    const printData = await request<BaseData<{ userData: any; tempData: any; }>>({
      url: '/api/cloud/wms/rest/print/template/sku/info',
      method: 'POST',
      data: {
        ids: detailItem.skuId,
        tempType,
        idType: 1,
        tempId,
        ownerId: detailItem.ownerId,
        shopId: detailItem.shopId,
        skuNumList: [
          {
            skuId: detailItem.skuId,
            number: 1,
            uniqueCode: detailItem.uniqueCode,
          },
        ],
      },
    });

    printHelper.toggleToRookie();
    await printHelper.print({
      preview: false,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userData,
    });
  };

  @action public handleUniqueCodePressEnter = async(uniqueCode: string) => {
    this.uniqueCode = '';
    this.uniqueCodeRef.current.focus();

    if (!uniqueCode) {
      playVoice(voiceData.请输入唯一码);
      return;
    }

    this.detailItem = {};

    try {
      const info = await request<BaseData<DetailItem>>({
        url: '/api/cloud/wms/rest/added/value_added/process',
        method: 'POST',
        data: {
          printUniqueCode: this.printUniqueCode,
          pickOrderId: this.waveValue,
          barCode: uniqueCode,
          valueAddedType: ENUM_PLUS_SERVICE_TYPE.changeTheTag.value,
        },
      });
      const detailItem: DetailItem = info.data || {};
      this.detailItem = detailItem;

      // 打印合格证
      if (detailItem.certificateTemplateId) {
        await this.printCertificateOrWaterWashingLabel(detailItem, detailItem.certificateTemplateId || 0, '7');
      }

      // 打印水洗唛
      if (detailItem.washIconTemplateId) {
        await this.printCertificateOrWaterWashingLabel(detailItem, detailItem.washIconTemplateId || 0, '6');
      }

      if (this.printUniqueCode) {
        const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
          url: '/api/cloud/baseinfo/rest/unique/code/print/unique/code',
          method: 'POST',
          data: {
            tempId: detailItem.uniqueCodeTemplateId || 0,
            tempType: '27',
            detailList: [
              {
                num: 1,
                skuId: detailItem.skuId,
                uniqueCode: detailItem.uniqueCode,
                id: detailItem.skuId,
              },
            ],
          },
        });

        printHelper.toggleToLodop();
        await printHelper.print({
          preview: false,
          templateData: printData?.data?.tempData,
          contents: printData?.data?.userDataList,
        });
      }

      if (detailItem.voice) {
        getAndPlayVoice(detailItem.voice);
      } else {
        playVoice(voiceData.通过);
      }
    } catch (e) {
      playVoice(voiceData.错误);
    }
  };
}
